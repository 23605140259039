"use client";

import { useObserveElementRef } from "../../../helpers/in-viewport/in-viewport";
import { BlocksUsefulSourcesRail } from "../../../__generated__/contentful-types";
import { notNullish } from "../../../helpers/notNullish";
import { getContentfulImageUrl } from "../../../helpers/images/getContentfulImageUrl";
import { SliderButton } from "../../slider-button/slider-button";
import { useTextDirectionContext } from "../../../helpers/TextDirectionContext";
import { useDevicePixelRatio } from "../../../helpers/useDevicePixelRatio";
import { useId } from "react";
import useEmblaCarousel from "embla-carousel-react";
import classnames from "classnames/bind";
import styles from "./useful-sources-rail.module.css";

const cx = classnames.bind(styles);

const CARD_HEIGHT = 480;
const LOGO_WIDTH = 100;

export const UsefulSourcesRail = ({
  block,
}: {
  block: BlocksUsefulSourcesRail;
}) => {
  const textDirection = useTextDirectionContext();
  const { ref, hasIntersected } = useObserveElementRef<HTMLDivElement>({
    threshold: 0,
  });

  const slides = block.usefulSourcesCollection?.items.filter(notNullish) || [];

  const devicePixelRatio = useDevicePixelRatio();

  const titleId = useId();

  const [emblaViewportRef, emblaApi] = useEmblaCarousel({
    direction: textDirection,
    loop: false,
    containScroll: "trimSnaps",
    draggable: true,

    breakpoints: {
      "(min-width: 1200px)": {
        slidesToScroll: 3,
        draggable: false,
      },
    },
  });

  return (
    <div
      className={cx("container")}
      ref={ref}
      role="group"
      aria-labelledby={titleId}
      aria-roledescription="carousel"
    >
      <div className={cx("header")}>
        <div className={cx("header-inner")}>
          <h2 id={titleId} className={cx("title")}>
            {block.title}
          </h2>
          <div
            role="group"
            aria-label="Slide controls"
            className={cx("slider-button-wrapper")}
          >
            <SliderButton
              kind="previous"
              className={cx("slider-button", "slider-button--prev")}
              onClick={() => emblaApi?.scrollPrev()}
            />
            <SliderButton
              kind="next"
              className={cx("slider-button", "slider-button--next")}
              onClick={() => emblaApi?.scrollNext()}
            />
          </div>
        </div>
      </div>
      <div className={cx("content")}>
        <div
          className={cx(
            "content-inner",
            `content-inner--${slides.length}-slides`,
          )}
        >
          {hasIntersected && (
            <div className={cx("embla-viewport")} ref={emblaViewportRef}>
              <div className={cx("embla-container")}>
                {slides.map((data, index) => (
                  <div className={cx("embla-slide")}>
                    <div
                      key={index}
                      className={cx("card-wrapper")}
                      data-index={String(index)}
                      role="group"
                      aria-label={`Slide ${index + 1} of ${slides.length}`}
                    >
                      <div className={cx("card-inner")}>
                        <a
                          className={cx(
                            "card",
                            "card--external",
                            "card--gradient-background",
                          )}
                          href={data.linkUrl ?? undefined}
                          target="_blank"
                        >
                          {data.backgroundImage && (
                            <img
                              className={cx("card-image")}
                              src={
                                data.backgroundImage.url
                                  ? getContentfulImageUrl(
                                      data.backgroundImage.url,
                                      {
                                        width: undefined,
                                        height: Math.round(
                                          CARD_HEIGHT * devicePixelRatio,
                                        ),
                                      },
                                    )
                                  : undefined
                              }
                              alt={
                                data.backgroundImage.description ?? undefined
                              }
                              draggable={false}
                              aria-hidden={true}
                            />
                          )}
                          <div className={cx("card-info")}>
                            {data.logo && (
                              <img
                                className={cx("card-logo")}
                                src={
                                  data.logo.url
                                    ? getContentfulImageUrl(data.logo.url, {
                                        width: Math.round(
                                          LOGO_WIDTH * devicePixelRatio,
                                        ),
                                        height: undefined,
                                        fit: "pad",
                                        fm: "png",
                                      })
                                    : undefined
                                }
                                draggable={false}
                                aria-hidden={true}
                              />
                            )}
                            <h3
                              className={cx("card-title")}
                              id={`card-title-${index}`}
                            >
                              {data.title}
                            </h3>
                            <div className={cx("card-link")}>
                              <div className={cx("card-link-icon")}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="none"
                                    d="M24 0v24H0V0"
                                    data-name="Bounding area"
                                  />
                                  <path d="M18.5 14H18a.49.49 0 0 0-.49.5v4h-12v-12h4A.5.5 0 0 0 10 6v-.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0-.5.5v14a.5.5 0 0 0 .5.5h14a.5.5 0 0 0 .5-.5v-5a.5.5 0 0 0-.5-.5z" />
                                  <path d="M18.6 5H13a.4.4 0 0 0-.29.68l2.44 2.43-4.19 4.2a.5.5 0 0 0 0 .71l.35.35a.51.51 0 0 0 .71 0l4.19-4.2 2.14 2.14A.4.4 0 0 0 19 11V5.4a.4.4 0 0 0-.4-.4z" />
                                </svg>
                              </div>
                              <span className={cx("card-link-text")}>
                                {data.linkText}
                              </span>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
